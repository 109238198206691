import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import axios from 'axios';
import { API_URL } from '../utils/constants';

const Pricing = () => {
  const [pricing, setPricing] = useState([]);
  const [language, setLanguage] = useState('en');
  const [countryCode, setCountryCode] = useState('US');
  const [ip, setIp] = useState('');
  const [texts, setTexts] = useState({});

  useEffect(() => {
    // Usar el servicio ipify para obtener la IP pública
    fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(data => setIp(data.ip))
      .catch(error => console.error('Error al obtener la IP:', error));
  }, []);

  useEffect(() => {
    const fetchCountryAndLanguage = async () => {
      if (!ip) return; // Evita la solicitud si `ip` está vacío

      try {
        const userLanguage = navigator.language.startsWith('es') ? 'es' : 'en';
        const response = await axios.get(`${API_URL}/cotizaciones/gettext/${ip}/${userLanguage}`);
        const data = response.data;
        setCountryCode(data.country);

        // Determinar el idioma y el país
        setLanguage(userLanguage);

        // Obtener textos basados en el país y el idioma
        setTexts(data.content);

        setPricing(Object.values(data.content)); // Asegurarse de que `pricing` es un array

      } catch (error) {
        console.error("Error fetching country data", error);
        // Usar textos por defecto en caso de error
        setPricing([
          {
            "title": "Basic",
            "description": [
              "Landing page",
              "Domain for one year",
              "Static files",
              "Email support"
            ],
            "buttonText": "Contact Us",
            "buttonVariant": "outlined",
            "price": "199"
          },
          {
            "title": "Standard",
            "description": [
              "Landing page",
              "Domain for 2 years",
              "Static files",
              "Google Login integration",
              "Priority email support",
              "Dedicated support"
            ],
            "buttonText": "Contact Us",
            "buttonVariant": "contained",
            "price": "399"
          },
          {
            "title": "Premium",
            "description": [
              "Landing page",
              "Domain for 5 years",
              "API integration",
              "Priority email support",
              "Dedicated support",
              "Custom solutions"
            ],
            "buttonText": "Contact Us",
            "buttonVariant": "outlined",
            "price": "599"
          }
        ]);
      }
    };

    fetchCountryAndLanguage();
  }, [countryCode, ip]); // Ejecuta el efecto cuando `ip` cambie

  return (
    <Container
      id="pricing"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        <Typography component="h2" variant="h4" color="text.primary">
          {language === 'es' ? 'Precios' : 'Pricing'}
        </Typography>
        <Typography variant="body1" color="text.secondary">
          {language === 'es' ? 'Encuentra el servicio que necesitas.' : 'Find the service you need.'}
        </Typography>
      </Box>
      <Grid container spacing={3} alignItems="center" justifyContent="center">
        {pricing.map((tier) => (
          <Grid
            item
            key={tier.title}
            xs={12}
            sm={6}
            md={4}
          >
            <Card
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                gap: 4,
                border: tier.title === 'Professional' ? '1px solid' : undefined,
                borderColor:
                  tier.title === 'Professional' ? 'primary.main' : undefined,
                background:
                  tier.title === 'Professional'
                    ? 'linear-gradient(#033363, #021F3B)'
                    : undefined,
              }}
            >
              <CardContent>
                <Box
                  sx={{
                    mb: 1,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    color:
                      tier.title === 'Professional' ? 'primary.contrastText' : '',
                  }}
                >
                  <Typography component="h3" variant="h6">
                    {tier.title}
                  </Typography>
                  {tier.title === 'Professional' && (
                    <Chip
                      icon={<AutoAwesomeIcon />}
                      label={tier.subheader}
                      size="small"
                      sx={{
                        background: (theme) =>
                          theme.palette.mode === 'light' ? '' : 'none',
                        backgroundColor: 'primary.contrastText',
                        '& .MuiChip-label': {
                          color: 'primary.dark',
                        },
                        '& .MuiChip-icon': {
                          color: 'primary.dark',
                        },
                      }}
                    />
                  )}
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'baseline',
                    color:
                      tier.title === 'Professional'
                        ? 'primary.contrastText'
                        : undefined,
                  }}
                >
                  <Typography component="h3" variant="h2">
                    ${tier.price}
                  </Typography>
                  <Typography component="h3" variant="h6">
                    &nbsp; {language === 'es' ? 'una vez' : 'one-time'}
                  </Typography>
                </Box>
                <Divider
                  sx={{
                    my: 2,
                    opacity: 0.2,
                    borderColor: 'grey.500',
                  }}
                />
                {tier.description.map((line) => (
                  <Box
                    key={line}
                    sx={{
                      py: 1,
                      display: 'flex',
                      gap: 1.5,
                      alignItems: 'center',
                    }}
                  >
                    <CheckCircleRoundedIcon
                      sx={{
                        width: 20,
                        color:
                          tier.title === 'Professional'
                            ? 'primary.light'
                            : 'primary.main',
                      }}
                    />
                    <Typography
                      component="text"
                      variant="subtitle2"
                      sx={{
                        color:
                          tier.title === 'Professional'
                            ? 'primary.contrastText'
                            : undefined,
                      }}
                    >
                      {line}
                    </Typography>
                  </Box>
                ))}
              </CardContent>
              <CardActions>
                <Button
                  fullWidth
                  variant={tier.buttonVariant}
                  component="a"
                  href="/material-ui/getting-started/templates/checkout/"
                  target="_blank"
                >
                  {tier.buttonText}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Pricing;
