import './App.css';
import LandingPage from './LandingPage'
import Dashboard from './components/dashboard/Dashboard';

function App() {
  return (
    <>
      {true ? <LandingPage/> : <Dashboard/>}
    </>
  );
}

export default App;
