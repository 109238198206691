import * as React from 'react';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';

export function AlertSuccess(props) {
    const {message} = props;
  return (
    <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
      {message}
    </Alert>
  );
}

export function AlertError(props) {
    const {message} = props;
  return (
    <Alert icon={<ErrorIcon fontSize="inherit" />} severity="error">
      {message}
    </Alert>
  );
}