import React, {useState, useEffect} from 'react';
import { alpha } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
// import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { validateEmail } from '../utils';
import { API_URL } from '../utils/constants';
import { AlertSuccess, AlertError } from '../utils/alerts';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import axios from 'axios';
import textos from '../textos/hero.json'; 

export default function Hero() {
  const [email, setEmail] = useState('');
  const [emailEnviado, setEmailEnviado] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [language, setLanguage] = useState('en');
  const [countryCode, setCountryCode] = useState('US');
  const [ip, setIp] = useState('');
  const [texts, setTexts] = useState({});

  useEffect(() => {
    // Usar el servicio ipify para obtener la IP pública
    fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(data => setIp(data.ip))
      .catch(error => console.error('Error al obtener la IP:', error));
  }, []);

  useEffect(() => {
    const fetchCountryAndLanguage = async () => {
      if (!ip) return; // Evita la solicitud si `ip` está vacío

      try {
        const userLanguage = navigator.language.startsWith('es') ? 'es' : 'en';
        const response = await axios.get(`${API_URL}/cotizaciones/gettext/${ip}/${userLanguage}`);
        const data = response.data;
        setCountryCode(data.country);

        // Determinar el idioma y el país
        setLanguage(userLanguage);

        // Obtener textos basados en el país y el idioma
        setTexts(textos[data.country] ? textos[countryCode][userLanguage] : textos['US'][userLanguage]);


      } catch (error) {
        console.error("Error fetching country data", error);
        // Usar textos por defecto en caso de error
        setTexts(textos['US'][language]);
      }
    
    };

    fetchCountryAndLanguage();
  }, [countryCode, ip]); // Ejecuta el efecto cuando `ip` cambie

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = () => {
    fetch(API_URL + '/cotizaciones', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    })
    .then(response => {
      if (response.ok) {
        console.log('Correo electrónico enviado correctamente');
        setEmail('');
        setEmailEnviado(true);
        setEmailError(false);
      } else {
        console.error('El correo ya se encuentra registrado');
        setEmailEnviado(false);
        setEmailError(true);
      }
    })
    .catch(error => {
      console.error('Error en la solicitud:', error);
    });
  };

  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: '100%',
        backgroundImage:
          theme.palette.mode === 'light'
            ? 'linear-gradient(180deg, #CEE5FD, #FFF)'
            : 'linear-gradient(#02294F, #090E10)',
        backgroundSize: '100% 20%',
        backgroundRepeat: 'no-repeat',
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        <Stack spacing={4} useFlexGap 
                sx={{ display: 'flex',
                      flexDirection: { xs: 'column', md: 'row'},
                      width: { md: '100%'} 
                      }}>
          
          <Stack sx={{
                alignItems: 'center',
                justifyContent: { xs: '', md: 'center'},
                width: { xs: '100%', sm:'100%', md: '60%'}
              }}>
              <Typography
                component="h1"
                variant="h1"
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', md: 'column' },
                  alignSelf: 'center',
                  textAlign: 'left',
                  padding: '15px'
                }}
              >
                {texts.title}
                <Typography
                  component="span"
                  variant="h1"
                  sx={{
                    color: (theme) =>
                      theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
                  }}
                >
                </Typography>
              </Typography>
              <Typography variant="body1" textAlign="center" color="text.secondary" sx={{
                  width: { xs: '300px', sm: '67%', md: '400px' },
                  textAlign: 'justify',
                  pt: { xs: 1}
              }}>
                {texts.description}
              </Typography>
              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                alignSelf="center"
                spacing={1}
                useFlexGap
                sx={{ pt: 6, width: { xs: '100%', sm: 'auto' } }}
              >
                <TextField
                  id="outlined-basic"
                  hiddenLabel
                  size="small"
                  variant="outlined"
                  aria-label="Ingresa tu correo electrónico"
                  placeholder={texts.cta}
                  value={email}
                  onChange={handleEmailChange}
                  inputProps={{
                    autoComplete: 'off',
                  }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!validateEmail(email)}
                  onClick={handleSubmit}
                >
                  {texts.cta}
                </Button>
              </Stack>
              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                alignSelf="center"
                spacing={1}
                useFlexGap
                sx={{ pt: 2, width: { xs: '100%', sm: 'auto' } }}
              >
                <Button
                  variant="outlined"
                  style={{ color: 'green', borderColor: 'green' }}
                  href="https://wa.me/59177858921"
                  target="_blank"
                >
                  <WhatsAppIcon /> WhatsApp
                </Button>
              </Stack>
            </Stack>
            
              
            <Box sx={{ pt: 1, width: { xs: '100%', md: '40%' } }}>
                <img style={{width:'100%'}} src={"/static/development.PNG"} />
            </Box>
          </Stack>

        <Stack>
          {!emailError && emailEnviado && <AlertSuccess message="Tu solicitud de cotización se envió correctamente."/>}
          {!emailEnviado && emailError && <AlertError message="El correo ya se encuentra registrado."/>}
        </Stack>
        <Box
          id="image"
          sx={(theme) => ({
            mt: { xs: 8, sm: 10 },
            alignSelf: 'center',
            height: { xs: 200, sm: 700 },
            width: '100%',
            backgroundImage:
              theme.palette.mode === 'light'
                ? 'url("/static/images/templates/hero-light.png")'
                : 'url("/static/images/templates/hero-dark.png")',
            backgroundSize: 'cover',
            borderRadius: '10px',
            outline: '1px solid',
            outlineColor:
              theme.palette.mode === 'light'
                ? alpha('#BFCCD9', 0.5)
                : alpha('#9CCCFC', 0.1),
            boxShadow:
              theme.palette.mode === 'light'
                ? `0 0 12px 8px ${alpha('#9CCCFC', 0.2)}`
                : `0 0 24px 12px ${alpha('#033363', 0.2)}`,
          })}
        />
      </Container>
    </Box>
  );
}